import Footer from "../components/footer";
import { React, useState, useContext } from "react";
import toast from "react-hot-toast";
import { AuthContext } from "../context/authContext";

import { Link } from "react-router-dom";
import http from "../components/http";

function Login(props) {
  // const {email,setEmail,setOTP,setPage} = RecoverPassword()
  //Toggle Password
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen((open) => !open);
  };
  const [password, passUser] = useState("");
  const [error, setError] = useState(false);
  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState("");
  //Reset Input

  //Login
  const loginHandler = async (e) => {
    e.preventDefault();
    setError(false);
    const inputs = {
      email,
      password,
    };

    try {
      await login(inputs);

      //   window.location.replace("/");
    } catch (err) {
      toast.error(err.response.data);
      setError(true);
    }
  };
  //console.log(email, password)
  const navigateToOtp = async () => {
    if (email)
      try {
        const OTP = Math.floor(Math.random() * 9000 + 1000);
        console.log(OTP);
        setOTP(OTP);
        const res = await http.get(
          `/email/reset-password?otp=${OTP}&email=${email}`
        );
        toast.success(res.data);
        //window.location.replace(`/reset-password/${email}/`)
      } catch (err) {
        console.log(err) && setError(true);
      }

    // http.post('/send_recovery_email',{
    // OTP, recipient_email:email
    // })
  };
  console.log(otp);

  const resetInput = {
    email,
    otp,
  };
  // return alert('Please enter your email')

  return (
    <>
      <div className="templatemo-content col-1 light-gray-bg">
        <div className="templatemo-content-widget templatemo-login-widget white-bg">
          <header className="text-center">
            <h1>EventsPlc App</h1>
            <p>Excellent Event Place Managers</p>
          </header>
          {error && (
            <span style={{ color: "red" }}> Login is unsuccessful</span>
          )}

          <form
            method="post"
            onSubmit={loginHandler}
            className="templatemo-login-form"
          >
            <div className="form-group">
              <label>
                <span>Enter Your Name</span>
              </label>
              <div className="input-group">
                <div className="input-group-addon">
                  <i className="fa fa-user fa-fw"></i>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your Email"
                  required={true}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="input-group">
              <label>
                <span>Enter Your Password</span>
              </label>
              <input
                type={isOpen ? "text" : "password"}
                className="form-control"
                id="password"
                placeholder="Your Password"
                required="true"
                onChange={(e) => passUser(e.target.value)}
              />
              <div className="input-group-addon">
                <i className={"fa fa-key fa-fw"} onClick={toggleOpen}></i>
              </div>
            </div>
            <div className="form-group">
              <div className="checkbox squaredTwo">
                <input type="checkbox" id="c1" name="cc" />
                <label htmlFor="c1">
                  <span></span>Remember me
                </label>
              </div>
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="templatemo-blue-button width-100"
              >
                Login
              </button>
            </div>
          </form>
        </div>
        <div className="templatemo-content-widget templatemo-login-widget templatemo-register-widget white-bg">
          <p>
            Forgot Password?{" "}
            <strong>
              <button onClick={navigateToOtp} className="blue-text">
                Click to Reset!
              </button>
            </strong>
          </p>
        </div>
        <div className="templatemo-content-widget templatemo-login-widget templatemo-register-widget white-bg">
          <p>
            Not a registered user yet?{" "}
            <strong>
              <a href="https://eventsplc.com/sign-up" className="blue-text">
                Sign up now!
              </a>
            </strong>
          </p>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Login;
