import React, { useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import { Link } from "react-router-dom";
import { calendarEndpoint } from "./http";
import useSWR from "swr";
import EventList from "../list/eventList";
import EventModal from "./eventModal";

function Calendar(props) {
  const [event, setEvents] = useState([]);
  const calendarRef = useRef(null);
  const [modalIsOpen, setModalOpen] = useState(false);

  /*function onEventAdded(event) {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.addEvent({
      start: moment(event.startDate).toDate(),
      end: moment(event.endDate).toDate(),
      title: event.title
    });
  }
  const [modalIsOpen, setModalOpen] = useState(false);
  
  

  async function handleEventAdd(data) {
    await axios.post("", data.event);
  }
*/
  //Get events

  const fetcher = (...args) =>
    fetch(...args).then((response) => response.json());
  const {
    isLoading,
    data: bookings,
    error,
  } = useSWR(calendarEndpoint, fetcher);

  if (isLoading) return <p>Data is loading</p>;
  async function handleSetEvent(date) {
    setEvents(date.event);
    setModalOpen(true);
  }
  if (error) return <p>Data failed to load</p>;
  return (
    <div class="templatemo-content-widget no-padding">
      <div class="panel panel-default table-responsive">
        <div class="templatemo-content-widget templatemo-login-widget templatemo-register-widget white-bg">
          <p>
            We are Growing,{" "}
            <strong>
              <Link to="/addBooking" class="blue-text">
                List the new Bookings here
              </Link>
            </strong>
          </p>
        </div>
        {/* Added FullCalendar*/}
        <button onClick={() => setModalOpen(true)}></button>
        <div style={{ position: "relative", zIndex: 0 }}>
          <FullCalendar
            ref={calendarRef}
            events={bookings}
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            // dateSet={(date) => handleSetEvent(date)}
            // eventAdd={(event) => handleEventAdd(event)}
            eventClick={(date) => handleSetEvent(date)}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,dayGridWeek, dayGridDay,listWeek",
            }}
          />{" "}
        </div>
        {
          /* Added addEventModal component with props*/
          <EventModal
            isOpen={modalIsOpen}
            onClose={() => setModalOpen(false)}
            onEventAdded={event}
          />
        }
        <EventList events={bookings} />
      </div>
    </div>
  );
}

export default Calendar;
