import React, { useContext } from "react";
import useSWR, { mutate } from "swr";
import { Link } from "react-router-dom";

import http, { calendarEndpoint } from "../components/http.jsx";
import toast from "react-hot-toast";
import { AuthContext } from "../context/authContext.js";

const handleDelete = async ({ id }) => {
  try {
    await http.delete(calendarEndpoint + id);
    mutate();
    toast.success("Tenant Deleted Successfully");
  } catch (error) {
    toast.error(error.message, {
      duration: 60,
    });
  }
};

const fetcher = (...args) => fetch(...args).then((response) => response.json());

function EventList({ events }) {
  const { currentUser } = useContext(AuthContext);
  let endPoint = calendarEndpoint + currentUser;

  const { isLoading, data: tenant, error } = useSWR(endPoint, fetcher);
  let num = 1;
  /* const changeHandler = (e) => {
    payment.filter((f) => f.rent.toLowerCase().includes(e.target.value));
  };*/

  if (error) return <p>{error.message}</p>;

  return (
    <div className="templatemo-content-container">
      {/* The table */}

      {/* The search 
      <form class="templatemo-search-form" role="search">
        <button type="submit" class="fa fa-search"></button>
        <input
          type="text"
          class="form-control"
          placeholder="Search Market here"
          name="srch-term"
          id="srch-term"
          onChange={changeHandler}
        />
      </form>*/}
      <div class="templatemo-content-widget no-padding">
        <div class="panel panel-default table-responsive">
          <table class="table table-striped table-bordered templatemo-user-table">
            <thead>
              <tr>
                <td>
                  <a href="" class="white-text templatemo-sort-by">
                    # <span class="caret"></span>
                  </a>
                </td>
                <td>
                  <a href="" class="white-text templatemo-sort-by">
                    Title <span class="caret"></span>
                  </a>
                </td>
                <td>
                  <a href="" class="white-text templatemo-sort-by">
                    Customer <span class="caret"></span>
                  </a>
                </td>
                <td>
                  <a href="" class="white-text templatemo-sort-by">
                    Phone <span class="caret"></span>
                  </a>
                </td>
                <td>
                  <a href="" class="white-text templatemo-sort-by">
                    Start <span class="caret"></span>
                  </a>
                </td>
                <td>
                  <a href="" class="white-text templatemo-sort-by">
                    End Date <span class="caret"></span>
                  </a>
                </td>
                <td>Edit</td>
                <td>View</td>
                <td>Delete</td>
              </tr>
            </thead>
            {events
              ? events.map((event, key) => {
                  return (
                    <tbody>
                      <tr key={key}>
                        <td>{num++}</td>
                        <td>{event.title}</td>
                        <td>{event.customer}</td>
                        <td>{event.phone}</td>
                        <td>{event.start}</td>
                        <td>{event.end}</td>
                        <td>
                          <Link
                            to={`/addTenant/${tenant.property}`}
                            state={tenant}
                            class="templatemo-edit-btn"
                          >
                            {" "}
                            Edit
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/property/${tenant.property}`}
                            state={tenant}
                            className="templatemo-link"
                          >
                            View
                          </Link>
                        </td>

                        <td>
                          <Link
                            to=""
                            onClick={() => handleDelete(tenant)}
                            class="templatemo-edit-btn"
                          >
                            Delete
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              : isLoading && <span>Loading...</span>}
          </table>
        </div>
      </div>
      {/* Pagination */}
    </div>
  );
}

export default EventList;
