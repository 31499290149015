import axios from "axios";

export default axios.create({
  baseURL: "https://cloud.eventsplc.com/api/",
  //withCredentials: true,
  // baseURL: "http://localhost:8000/api/",
  headers: {
    "Content-Type": "application/json",
  },
});
export const imgUrl = "https://cloud.eventsplc.com/images/";
const url = "https://cloud.eventsplc.com/api/";
// const url = "http://localhost:8000/api/";
export const postUrlEndpoint = "https://api.eventsplc.com/api/post/";

export const venueUrlEndpoint = url + "venues/";
export const calendarEndpoint = url + "events/";
export const defaultUrlEndpoint = url + "payment/default";

export const paymentUrlEndpoint = url + "payment/";
export const tenantUrlEndpoint = url + "tenant/";
export const marketUrlEndpoint = url + "market/";
export const userUrlEndpoint = url + "users/";
export const newuserUrlEndpoint = url + "newusers/";

export const getmarketUrlEndpoint = "https://api.eventsplc.com/api/market/";

export const options = [
  { label: "Art Gallery", value: "Art Gallery" },
  { label: "Bars and Nightclub", value: "Bars and Nightclub" },
  { label: "Community Centre", value: "Community Centre" },
  { label: "Conference Center", value: "Conference Centre" },
  { label: "Event Halls", value: "Event Halls" },
  { label: "Hotel", value: "Hotel" },
  { label: "Pool side", value: "Pool Side" },
  { label: "Resorts", value: "Resorts" },
  { label: "Stadium/Arenas", value: "Stadium/Arenas" },
  { label: "Tents", value: "Tents" },
];
