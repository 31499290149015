import React from "react";
import Nav from "../components/nav";
import Sidebar from "../components/sideBar";
import Details from "../components/details";
import PaymentList from "../list/PaymentList";
import AllList from "../list/AllList";
function Defaults() {
  return (
    <div>
      <div class="templatemo-flex-row">
        <Sidebar />
        <div class="templatemo-content col-1 light-gray-bg">
          <Nav />

          <AllList />
        </div>
      </div>
    </div>
  );
}

export default Defaults;
