import React, { useContext } from "react";
import useSWR, { mutate } from "swr";
import { Link } from "react-router-dom";

import http, { tenantUrlEndpoint } from "../components/http.jsx";
import toast from "react-hot-toast";
import { AuthContext } from "../context/authContext.js";

const handleDelete = async ({ id }) => {
  try {
    await http.delete(tenantUrlEndpoint + id);
    mutate();
    toast.success("Tenant Deleted Successfully");
  } catch (error) {
    toast.error(error.message, {
      duration: 60,
    });
  }
};

const fetcher = (...args) => fetch(...args).then((response) => response.json());

function TenantList({ id }) {
  const { currentUser } = useContext(AuthContext);

  let endPoint = tenantUrlEndpoint + id;

  const { isLoading, data: tenant, error } = useSWR(endPoint, fetcher);
  let num = 1;
  /* const changeHandler = (e) => {
    payment.filter((f) => f.rent.toLowerCase().includes(e.target.value));
  };*/

  if (error) return <p>{error.message}</p>;

  return (
    <div className="templatemo-content-container">
      <div class="templatemo-flex-row flex-content-row">
        <div class="templatemo-flex-row flex-content-row">
          <div class="templatemo-content-widget white-bg col-2">
            <i class="fa fa-times"></i>
            <div class="media margin-bottom-30">
              <div class="media-body">
                <h2 class="media-heading text-uppercase blue-text">
                  {currentUser.username}
                </h2>
                <p>{currentUser.email}</p>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table">
                <tbody>
                  <tr>
                    <td>
                      <div class="circle green-bg"></div>
                    </td>
                    <td>Total Tenants</td>
                    <td>{tenant && tenant.length}</td>
                  </tr>

                  <tr>
                    <td>
                      <div class="circle yellow-bg"></div>
                    </td>
                    <td>Vacant Apartments</td>
                    <td>18</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="templatemo-content-widget white-bg col-1 templatemo-position-relative templatemo-content-img-bg">
            <img
              src="assets/images/sunset-big.jpg"
              alt="Sunset"
              class="img-responsive content-bg-img"
            />
            <i class="fa fa-heart"></i>
            <h2 class="templatemo-position-relative white-text">Sunset</h2>
            <div class="view-img-btn-wrap">
              <a href="" class="btn btn-default templatemo-view-img-btn">
                View
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* The table */}
      <div class="templatemo-content-widget templatemo-login-widget templatemo-register-widget white-bg">
        <p>
          We are Growing,{" "}
          <strong>
            <Link to={`/addTenant/`} class="blue-text">
              List the new Tenant here
            </Link>
          </strong>
        </p>
      </div>
      {/* The search 
      <form class="templatemo-search-form" role="search">
        <button type="submit" class="fa fa-search"></button>
        <input
          type="text"
          class="form-control"
          placeholder="Search Market here"
          name="srch-term"
          id="srch-term"
          onChange={changeHandler}
        />
      </form>*/}
      <div class="templatemo-content-widget no-padding">
        <div class="panel panel-default table-responsive">
          <table class="table table-striped table-bordered templatemo-user-table">
            <thead>
              <tr>
                <td>
                  <a href="" class="white-text templatemo-sort-by">
                    # <span class="caret"></span>
                  </a>
                </td>
                <td>
                  <a href="" class="white-text templatemo-sort-by">
                    Tenant <span class="caret"></span>
                  </a>
                </td>
                <td>
                  <a href="" class="white-text templatemo-sort-by">
                    Apartment <span class="caret"></span>
                  </a>
                </td>
                <td>
                  <a href="" class="white-text templatemo-sort-by">
                    Rent <span class="caret"></span>
                  </a>
                </td>
                <td>
                  <a href="" class="white-text templatemo-sort-by">
                    Phone <span class="caret"></span>
                  </a>
                </td>
                <td>Edit</td>
                <td>View</td>
                <td> Tenants</td>
                <td>Delete</td>
              </tr>
            </thead>
            {tenant
              ? tenant.map((tenant, key) => {
                  return (
                    <tbody>
                      <tr key={key}>
                        <td>{num++}</td>
                        <td>{tenant.name}</td>
                        <td>{tenant.description}</td>
                        <td>{tenant.rent}</td>
                        <td>{tenant.phone}</td>
                        <td>
                          <Link
                            to={`/addTenant/${tenant.property}`}
                            state={tenant}
                            class="templatemo-edit-btn"
                          >
                            {" "}
                            Edit
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/property/${tenant.property}`}
                            state={tenant}
                            className="templatemo-link"
                          >
                            View
                          </Link>
                        </td>
                        <td>
                          {" "}
                          <Link
                            to={`/addRent/${tenant.name}/${tenant.property}/`}
                            className="templatemo-link"
                          >
                            Add Payment
                          </Link>
                        </td>
                        <td>
                          <Link
                            to=""
                            onClick={() => handleDelete(tenant)}
                            class="templatemo-edit-btn"
                          >
                            Delete
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              : isLoading && <p>Loading...</p>}
          </table>
        </div>
      </div>
      {/* Pagination */}
    </div>
  );
}

export default TenantList;
