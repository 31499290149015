import React, { useState, useContext } from "react";
import Nav from "../components/nav";
import http, { postUrlEndpoint } from "../components/http";
import { Link } from "react-router-dom";
import Sidebar from "../components/sideBar";
import parse from "html-react-parser";
import Pagination from "../components/pagination";
import toast from "react-hot-toast";
import useSWR, { mutate } from "swr";
import { AuthContext } from "../context/authContext";
import SidebarAdmin from "../components/sidebarAdmin";
import NavAdmin from "../components/navAdmin";

const handleDelete = async ({ id }) => {
  try {
    await http.delete(postUrlEndpoint + id);
    mutate();
    toast.success("Post Added Successfully");
  } catch (error) {
    toast.error(error.message, {
      duration: 60,
    });
  }
};

const fetcher = (...args) => fetch(...args).then((response) => response.json());

function Blog() {
  const { currentUser } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);

  let endPoint = postUrlEndpoint;
  const { isLoading, data: post, error } = useSWR(endPoint, fetcher);
  if (isLoading) {
    return <div>Loading data...</div>;
  }

  if (error) return <p>{error.message}</p>;

  //Search

  //for pagination
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const orders = post.slice(firstIndex, lastIndex);

  const paginate = (numbers) => setCurrentPage(numbers);
  let num = 1;

  {
    /* Search input
  const [inputs, setInputs] = useState([]);
  //

  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = posts.slice(firstIndex, lastIndex);
  const npage = Math.ceil(posts.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  // console.log(records);
  useEffect(() => {
    const fetchPosts = async () => {
      //  const res = await axios.get("/posts");
      const res = await http.get(`posts`);
      setInputs(res.data);
      setPosts(res.data);
    };
    fetchPosts();
  }, []);
  console.log(records);
  //for Search
  const changeHandler = (e) => {
    setPosts(
      inputs.filter((f) => f.body.toLowerCase().includes(e.target.value))
    );
  };

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changePage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }
*/
  }
  return (
    <div>
      <div className="templatemo-flex-row">
        <SidebarAdmin />
        <div className="templatemo-content col-1 light-gray-bg">
          <NavAdmin />
          <div className="templatemo-content-container">
            <div className="templatemo-flex-row flex-content-row">
              <div className="templatemo-flex-row flex-content-row">
                <div className="templatemo-content-widget white-bg col-2">
                  <i className="fa fa-times"></i>
                  <div className="media margin-bottom-30">
                    <div className="media-left padding-right-25">
                      <a href="#">
                        <img
                          className="media-object img-circle templatemo-img-bordered"
                          src="assets/images/person.jpg"
                          alt="Sunset"
                        />
                      </a>
                    </div>
                    <div className="media-body">
                      <h2 className="media-heading text-uppercase blue-text">
                        {currentUser.username}
                      </h2>
                      <p>{currentUser.email}</p>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td>
                            <div className="circle green-bg"></div>
                          </td>
                          <td>Total Posts</td>
                          <td>{post && post.length}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* The table */}
            <div className="templatemo-content-widget templatemo-login-widget templatemo-register-widget white-bg">
              <p>
                We are Growing,{" "}
                <strong>
                  <Link to="/post" className="blue-text">
                    List the new Articles Here
                  </Link>
                </strong>
              </p>
            </div>
            {/* The search 
            <form className="templatemo-search-form" role="search">
              <button type="submit" className="fa fa-search"></button>
              <input
                type="text"
                className="form-control"
                placeholder="Search Market here"
                name="srch-term"
                id="srch-term"
                onChange={changeHandler}
              />
            </form>*/}
            <div className="templatemo-content-widget no-padding">
              <div className="panel panel-default table-responsive">
                <table className="table table-striped table-bordered templatemo-user-table">
                  <thead>
                    <tr>
                      <td>
                        <a href="" className="white-text templatemo-sort-by">
                          # <span className="caret"></span>
                        </a>
                      </td>
                      <td>
                        <a href="" className="white-text templatemo-sort-by">
                          Title <span className="caret"></span>
                        </a>
                      </td>
                      <td>
                        <a href="" className="white-text templatemo-sort-by">
                          slug <span className="caret"></span>
                        </a>
                      </td>
                      <td>
                        <a href="" className="white-text templatemo-sort-by">
                          Description <span className="caret"></span>
                        </a>
                      </td>
                      <td>
                        <a href="" className="white-text templatemo-sort-by">
                          Body <span className="caret"></span>
                        </a>
                      </td>
                      <td>Edit</td>
                      <td>View</td>
                      <td>Delete</td>
                    </tr>
                  </thead>
                  {orders &&
                    orders.map((m, key) => {
                      return (
                        <tbody>
                          <tr key={key}>
                            <td>{num++}</td>
                            <td>{m.title}</td>
                            <td>{m.slug}</td>
                            <td>{m.description}</td>
                            <td>{parse(m.body.slice(0, 200))}...</td>
                            <td>
                              <Link
                                to={`/post/`}
                                state={m}
                                className="templatemo-edit-btn"
                              >
                                {" "}
                                Edit
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={`/blog/${m.slug}`}
                                state={m}
                                classNameName="templatemo-link"
                              >
                                View
                              </Link>
                            </td>

                            <td>
                              <Link
                                to=""
                                onClick={() => handleDelete(m)}
                                className="templatemo-edit-btn"
                              >
                                Delete
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                </table>
                {/* Pagination */}
                <Pagination
                  order={post.length}
                  recordsPerPage={recordsPerPage}
                  paginate={paginate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
