import Footer from "../components/footer";
import { React, useState, useContext } from "react";
import toast from "react-hot-toast";
import { AuthContext } from "../context/authContext";
import { redirect, useLocation } from "react-router-dom";
import { isTokenExpired } from "../data/isTokenExpired";
import http from "../components/http";

function ConfirmPassword() {

  const location = useLocation();
  //const property = location.pathname.split("?")[2];
  const token = location.search.split("=")[1]
  console.log(token);
 // const email = location.search.split("=")[2]
 // console.log(email);

  //Toggle Password
const [isOpen, setIsOpen] = useState(false);
const toggleOpen = () => {
  setIsOpen((open) => !open);
};
  const [password, passUser] = useState("");
 // const [username, emailUser] = useState("");
  const [error, setError] = useState(false);
 // const { login } = useContext(AuthContext);
  //Login

  const tokenStatus = isTokenExpired(token)
  if(!token || tokenStatus===true){
    redirect('/register')
}
    
  const loginHandler = async (e) => {
    e.preventDefault();
    setError(false);
   
    try {
      const res = await http.post(`auth/forget-password/${token}`,{
        password, token 
      });
       toast.success(res.data)
      window.location.replace("/");
    } catch (err) {
      console.log(err)
      toast.error(err.response.data)
      setError(true);    }
  };
      
    
  return (
    <>
      <div className="templatemo-content col-1 light-gray-bg">
        <div className="templatemo-content-widget templatemo-login-widget white-bg">
          <header className="text-center">
            <div className="square"></div>
            <h1>Rent And Flex</h1>
            <p>Excellent Property Managers</p>
          </header>
          {error && (
            <span style={{ color: "red" }}> Password Reset is unsuccessful</span>
          )}

          <form
            method="post"
            onSubmit={loginHandler}
            className="templatemo-login-form"
          >
            <div className="input-group">
                
                <input
                  type={isOpen?"text":"password"}
                  className="form-control"
                  id="password"
                  placeholder="Your Password"
                  required={true}
                  onChange={(e) => passUser(e.target.value)}
                /><div className="input-group-addon" >
                  
                  <i className={isOpen?"fa fa-key fa-fw":"fa fa-eye"}onClick={toggleOpen}></i>
                </div>
              </div>
        
         
            <div className="form-group">
              <button
                type="submit"
                className="templatemo-blue-button width-100"
              >
                Change Password
              </button>
            </div>
          </form>
        </div>
        <div className="templatemo-content-widget templatemo-login-widget templatemo-register-widget white-bg">
          <p>
            Link expires in {" "}
            <strong>
              <a href="https://home.rentandflex.com/sign-up" className="blue-text">
                5 minutes
              </a>
            </strong>
          </p>
        </div>
        
        <Footer />
      </div>
    </>
  );
}

export default ConfirmPassword;
