import Describe from "../components/describe";
import Footer from "../components/footer";
import Nav from "../components/nav";
import Sidebar from "../components/sideBar";
import Details from "../components/details";
import MarketProfile from "../components/marketProfile";
import Card from "../components/card";

function Home() {
  return (
    <>
      <div className="templatemo-flex-row">
        <Sidebar />
        <div className="templatemo-content col-1 light-gray-bg">
          <Nav />
          <div className="templatemo-content-container">
            {/*Banner Column
            {market && market.map((m) => console.log(m))}*/}
<Card/>
            <MarketProfile />
            <Describe />
            <Details />

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
