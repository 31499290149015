import { useState } from "react";
import React from "react";

function Multifield(props) {
  const [hallData, setHallData] = useState([
    {
      //  hallName: "",
      hallCapacity: "",
      hallPrice: "",
    },
  ]);

  const handleClick = () => {
    setHallData([
      ...hallData,
      {
        hallCapacity: "",
        hallPrice: "",
      },
    ]);
  };

  const handleChange = (e, i) => {
    const { name, value } = e.target;
    const onChangeVal = [...hallData];
    onChangeVal[i][name] = value;
    setHallData(onChangeVal);
    let price = JSON.stringify(hallData);
    props.setField(price);
  };

  const handleDelete = (i) => {
    const deleteVal = [...hallData];
    deleteVal.splice(i, 1);
    setHallData(deleteVal);
  };
  return (
    <div>
      <div class="col-lg-12 align-self-center">
        <div class="row">
          {hallData.map((val, i) => (
            <>
              <div class="form-group">
                <input
                  type="text"
                  min="100"
                  step="any"
                  name="hallCapacity"
                  value={val.hallCapacity}
                  placeholder="Durations for e.g 1 hour"
                  autocomplete="on"
                  required
                  onChange={(e) => handleChange(e, i)}
                />
              </div>

              <div class="form-group">
                <input
                  type="number"
                  min="1"
                  step="any"
                  name="hallPrice"
                  value={val.hallPrice}
                  placeholder="Price"
                  autocomplete="on"
                  required
                  onChange={(e) => handleChange(e, i)}
                />
              </div>
            </>
          ))}
        </div>
      </div>
      <br />
      <button onClick={handleClick}>Add</button>
      <button onClick={(i) => handleDelete(i)}>Delete</button>
    </div>
  );
}

export default Multifield;
