import React from 'react'
import { Link } from "react-router-dom";


export default function NavAdmin() {
  return (
<div className="templatemo-top-nav-container">
      <div className="row">
        <nav className="templatemo-top-nav col-lg-12 col-md-12">
          <ul className="text-uppercase">
            <li>
              <Link to="/" className="active">
Dashboard              </Link>
            </li>
             <li>
              <Link to="/addVenue">Add Venue</Link>
            </li>
         
              <li>
                <Link to="/blog">Blog</Link>
              </li>
          
            
              <li>
                <Link to="/registration/">Sign up form</Link>
              </li>
            
                
            
          </ul>
        </nav>
      </div>
    </div>
  )
}
