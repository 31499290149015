import React from "react";
import Sidebar from "../components/sideBar";
import Nav from "../components/nav";
import Details from "../components/details";
import Calendar from "../components/calendar";

function Bookings(props) {
  return (
    <div class="templatemo-flex-row">
      <Sidebar />
      <div class="templatemo-content col-1 light-gray-bg">
        <Nav />
        <div class="templatemo-content-container">
          <Details />
          <Calendar />
          
        </div>
      </div>
    </div>
  );
}

export default Bookings;
