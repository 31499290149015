import React, { useContext } from "react";
import useSWR, { mutate } from "swr";
import { Link } from "react-router-dom";

import { AuthContext } from "../context/authContext.js";
import http, { userUrlEndpoint } from "../components/http.jsx";
import toast from "react-hot-toast";

const handleDelete = async ({ id }) => {
  try {
    await http.delete(userUrlEndpoint + id);
    mutate();
    toast.success("Users Deleted Successfully");
  } catch (error) {
    toast.error(error.message, {
      duration: 10,
    });
  }
};

const fetcher = (...args) => fetch(...args).then((response) => response.json());

function AllUserList(props) {
  const { currentUser } = useContext(AuthContext);
  let endPoint = userUrlEndpoint;
  const { isLoading, data: users, error } = useSWR(endPoint, fetcher);
  let num = 1;

  return (
    <div>
      {/* The table */}

      {/* The search */}
      <form class="templatemo-search-form" role="search">
        <button type="submit" class="fa fa-search"></button>
        <input
          type="text"
          class="form-control"
          placeholder="Search Users here"
          name="srch-term"
          id="srch-term"
          // onChange={changeHandler}
        />
      </form>

      <table class="table table-striped table-bordered templatemo-user-table">
        <thead>
          <tr>
            <td>
              <a href="" class="white-text templatemo-sort-by">
                # <span class="caret"></span>
              </a>
            </td>
            <td>
              <a href="" class="white-text templatemo-sort-by">
                User <span class="caret"></span>
              </a>
            </td>
            <td>
              <a href="" class="white-text templatemo-sort-by">
                Email <span class="caret"></span>
              </a>
            </td>
            <td>
              <a href="" class="white-text templatemo-sort-by">
                Role <span class="caret"></span>
              </a>
            </td>

            <td>Edit</td>
            
            <td>Delete</td>
          </tr>
        </thead>
        {users
          ? users.map((user, key) => {
              return (
                <tbody>
                  <tr key={key}>
                    <td>{num++}</td>
                    <td>
                      <Link
                        to={`/property/${user.slug}`}
                        state={user}
                        className="templatemo-link"
                      >
                        {user.username}
                      </Link>
                    </td>
                    <td>{user.email}</td>
                    <td>{user.role}</td>
                    <td>
                      <Link
                        to={`/registration/`}
                        state={user}
                        class="templatemo-edit-btn"
                      >
                        {" "}
                        Edit
                      </Link>
                    </td>

                   

                 
                    <td>
                      <Link
                        to=""
                        onClick={() => handleDelete(user)}
                        class="templatemo-edit-btn"
                      >
                        Delete
                      </Link>
                    </td>
                  </tr>
                </tbody>
              );
            })
          : isLoading && <p>Loading...</p>}
      </table>
      {/* Pagination */}
{      error && <p>{error.message}</p>
}
    </div>
  );
}

export default AllUserList;
