import React from "react";
import useSWR from "swr";
import {
  venueUrlEndpoint,
  marketUrlEndpoint,
  paymentUrlEndpoint,
  calendarEndpoint,
} from "../components/http";
import { useContext } from "react";
import { AuthContext } from "../context/authContext";

function MarketProfile(props) {
  const fetcher = (...args) =>
    fetch(...args).then((response) => response.json());
  const { currentUser } = useContext(AuthContext);
  const endPoint1 = venueUrlEndpoint + currentUser.firm;
  const endPoint2 = paymentUrlEndpoint + currentUser.firm;
  const endPoint3 = marketUrlEndpoint + currentUser.firm;
  const endPoint4 = calendarEndpoint + currentUser.firm;

  const {
    isLoading: isptyLoading,
    data: property,
    error,
  } = useSWR(endPoint1, fetcher);
  const {
    isLoading: ispayLoading,
    data: payment,
    error: err,
  } = useSWR(endPoint2, fetcher);
  const {
    isLoading: ismarketLoading,
    data: market,
    error: errs,
  } = useSWR(endPoint3, fetcher);
  const {
    isLoading: iseventsLoading,
    data: events,
    error: errevs,
  } = useSWR(endPoint4, fetcher);
  if (iseventsLoading) return <p>{"data is loading"}</p>;
  if (ismarketLoading) return <p>{"data is loading"}</p>;
  if (ispayLoading) return <p>{"data is loading"}</p>;
  if (isptyLoading) return <p>{"data is loading"}</p>;
  if ((error, err, errs, errevs)) return <p>{"Failed to fetch"}</p>;

  let summary1 = property && property.length;
  let summary2 = payment && payment.length;
  let summary3 = events && events.length;

  return (
    <div className="templatemo-flex-row flex-content-row">
      {market ? (
        <div className="templatemo-content-widget white-bg col-2">
          <i className="fa fa-times"></i>
          <div className="square"></div>
          <h2 className="text-uppercase">{market && market.title}</h2>

          <hr />
          <p>{market && market.description} </p>
        </div>
      ) : (
        ismarketLoading && <p>Loading connection</p>
      )}
      <div className="templatemo-content-widget white-bg col-1 text-center">
        <i className="fa fa-times"></i>
        <h2 className="templatemo-inline-block">Public Photo</h2>
        <h3 className="text-lowercase margin-bottom-10"></h3>
        <img
          src={"assets/images/profile-photo.jpg"}
          alt="Bicycle"
          className="img-circle img-thumbnail"
        />
      </div>
      <div className="templatemo-content-widget white-bg col-1">
        <i className="fa fa-times"></i>
        <h2 className="text-uppercase">Summary</h2>
        <h3 className="text-uppercase">2024</h3>
        <hr />
        <div className="table-responsive">
          <table className="table">
            <tbody>
              <tr>
                <td>
                  <div className="circle green-bg"></div>
                </td>
                <td>Total Venues</td>
                {summary1 ? (
                  <td>{summary1}</td>
                ) : (
                  isptyLoading && <p>Loading data</p>
                )}
              </tr>
              <tr>
                <td>
                  <div className="circle pink-bg"></div>
                </td>
                <td>Total Payments</td>
                {summary2 ? (
                  <td>{summary2}</td>
                ) : (
                  ispayLoading && <p>Loading data</p>
                )}
              </tr>
              <tr>
                <td>
                  <div className="circle blue-bg"></div>
                </td>
                <td>Total Bookings</td>
                {summary3 ? (
                  <td>{summary3}</td>
                ) : (
                  iseventsLoading && <p>Loading data</p>
                )}
              </tr>
              <tr>
                <td>
                  <div className="circle yellow-bg"></div>
                </td>
                <td>New Notifications</td>
                <td>18</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="progress">
          <div
            className="progress-bar progress-bar-warning"
            role="progressbar"
            aria-valuenow="60"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: 60 }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default MarketProfile;
