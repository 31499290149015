import React from "react";
import "./describe.css";

function Describe(props) {
  return (
    <div class="col-1">
      <div class="templatemo-content-widget orange-bg">
        <i class="fa fa-times"></i>
        <div class="media">
          <div class="media-left">
            <a href="#">
              <img
                class="media-object img-circle"
                src="assets/images/sunset.jpg"
                alt="Sunset"
              />
            </a>
          </div>
          <div class="media-body">
            <h2 class="media-heading text-uppercase">Subscription</h2>
            <p>Your subscription will expire in 10 days</p>
          </div>
        </div>
      </div>
      <div class="templatemo-content-widget white-bg">
        <i class="fa fa-times"></i>
        <div class="media">
          <div class="media-left">
            <a href="#">
              <img
                class="media-object img-circle"
                src="assets/images/sunset.jpg"
                alt="Sunset"
              />
            </a>
          </div>
          <div class="media-body">
            <h2 class="media-heading text-uppercase">News and Articles</h2>
            <p>
              Read Up topical issues and News on property management from our
              blog. Click here
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Describe;
