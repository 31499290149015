import React from "react";
import useSWR, { mutate } from "swr";
import { Link } from "react-router-dom";

import http, {
  marketUrlEndpoint,
  
  venueUrlEndpoint,
} from "../components/http.jsx";
import toast from "react-hot-toast";

const handleDelete = async ({ id }) => {
  try {
    await http.delete(venueUrlEndpoint + id);
    toast.success("Deleted Successfully");
  } catch (error) {
    toast.error(error.message, {
      duration: 10,
    });
  }
};

const fetcher = (...args) => fetch(...args).then((response) => response.json());

function AllList(props) {
  let endPoint = venueUrlEndpoint;
  const { isLoading, data: property, error } = useSWR(endPoint, fetcher);
  let num = 1;

  const changeHandler = (e) => {
    property.filter((f) => f.address.toLowerCase().includes(e.target.value));
  };
  if (error) return <p>{error.message}</p>;

  return (
    <div>
      {/* The table */}
      <div className="templatemo-content-widget templatemo-login-widget templatemo-register-widget white-bg">
        <p>
          We are Growing,{" "}
          <strong>
            <Link to="/addVenue" className="blue-text">
              List the new Venue here
            </Link>
          </strong>
        </p>
      </div>
      {/* The search */}
      <form className="templatemo-search-form" role="search">
        <button type="submit" className="fa fa-search"></button>
        <input
          type="text"
          className="form-control"
          placeholder="Search Market here"
          name="srch-term"
          id="srch-term"
          onChange={changeHandler}
        />
      </form>

      <table className="table table-striped table-bordered templatemo-user-table">
        <thead>
          <tr>
            <td>
              <a href="" className="white-text templatemo-sort-by">
                # <span className="caret"></span>
              </a>
            </td>
            <td>
              <a href="" className="white-text templatemo-sort-by">
                Venues <span className="caret"></span>
              </a>
            </td>
            <td>
              <a href="" className="white-text templatemo-sort-by">
                City <span className="caret"></span>
              </a>
            </td>
            <td>
              <a href="" className="white-text templatemo-sort-by">
                Category <span className="caret"></span>
              </a>
            </td>
            <td>
              <a href="" className="white-text templatemo-sort-by">
                Phone <span className="caret"></span>
              </a>
            </td>
            <td>Edit</td>
            <td>View</td>
            <td>Delete</td>
          </tr>
        </thead>
        {property
          ? property.map((property, key) => {
              return (
                <tbody>
                  <tr key={key}>
                    <td>{num++}</td>
                    <td>
                      <Link
                        to={`/property/${property.slug}`}
                        state={property}
                        className="templatemo-link"
                      >
                        {property.title}
                      </Link>
                    </td>
                    <td>{property.city}</td>
                    <td>{property.category}</td>
                    <td>{property.phone}</td>
                    <td>
                      <Link
                        to={`/addVenue/`}
                        state={property}
                        className="templatemo-edit-btn"
                      >
                        {" "}
                        Edit
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/property/${property.slug}`}
                        state={property}
                        className="templatemo-link"
                      >
                        View
                      </Link>
                    </td>
                    
                    <td>
                      <Link
                        to=""
                        onClick={() => handleDelete(property)}
                        className="templatemo-edit-btn"
                      >
                        Delete
                      </Link>
                    </td>
                  </tr>
                </tbody>
              );
            })
          : isLoading && <span>Loading...</span>}
      </table>
      {/* Pagination */}
    </div>
  );
}

export default AllList;
