import React, { useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import $ from "jquery";
import { findDOMNode } from "react-dom";

function Sidebar(props) {
  const { currentUser, logOut } = useContext(AuthContext);
  const componentRef = useRef();
  const handleToggle = () => {
    const el = findDOMNode(componentRef.current);
    $(el).slideToggle();
  };
  /*{const scrollHandler = () => {
    ".templatemo-left-nav".slideToggle();
  };}*/
  return (
    <div className="templatemo-sidebar">
      <header className="templatemo-site-header">
        <div className="square"></div>
        <h1>Events Plc</h1>
        <p style={{color:"pink"}}>Excellent Event Hall Managers</p>
      </header>
      <div className="profile-photo-container">
       
        <div className="profile-photo-overlay"></div>
      </div>
      <form className="templatemo-search-form" role="search">
        <div className="input-group">
          <button type="submit" className="fa fa-search"></button>
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            name="srch-term"
            id="srch-term"
          />
        </div>
      </form>
      <div className="mobile-menu-icon" onClick={(e) => handleToggle(e)}>
        <i className="fa fa-bars"></i>
      </div>
      <nav className="templatemo-left-nav" ref={componentRef}>
        <ul>
          <li>
            <Link to="/">
              <i className="fa fa-home fa-fw"></i>Dashboard
            </Link>
          </li>
          
          <li>
            <Link to="/profile">
              <i className="fa fa-home fa-fw"></i>Profiles
            </Link>
          </li>
          <li>
            <Link to="/bookings">
              <i className="fa fa-bar-chart fa-fw"></i>Bookings
            </Link>
          </li>
          
          <li>
            <Link to="/property">
              <i className="fa fa-map-marker fa-fw"></i>Venues
            </Link>
          </li>
          <li>
            <Link to="/payment">
              <i className="fa fa-database fa-fw"></i>Payments
            </Link>
          </li>
          
          <li>
            <Link to="/user/">
              <i className="fa fa-users fa-fw"></i>Firm Users
            </Link>
          </li>
         
          {currentUser && (
            <li onClick={logOut}>
           <a>
              <i className="fa fa-eject fa-fw"></i>Sign Out
            </a></li>
          ) }
        </ul>
      </nav>
    </div>
  );
}

export default Sidebar;
/* Mobile menu */

/* Close the widget when clicked on close button 
".templatemo-content-widget .fa-times".click(function () {
  this.parent().slideUp(function () {
    this.hide();
  });
});*/
