import React, { useState, useContext } from "react";
import Nav from "../components/nav";
import http from "../components/http";
import { Context } from "../context/Context";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

function AddExpense() {
  const [title, titleRef] = useState("");
  const { user } = useContext(Context);

  const myString = title;
  const slug = myString.replace(/\s+/g, "-").toLowerCase();
  //console.log(slug);

  const [phone, phoneRef] = useState("");
  const [description, landmarkRef] = useState("");
  const [error, setError] = useState(false);
  // Date state
  const [startDate, setStartDate] = useState(new Date());
  let rentdate = moment(startDate, "MM/DD/YYYY");
  let rentdoc = rentdate.clone();

  let today = moment();

  console.log(today._d);

  //submit handler
  const writeHandler = async (e) => {
    e.preventDefault();
    setError(false);

    const postItems = {
      username: user.username,
      title,
      slug,
      phone,
      description,
    };

    try {
      // const res = await axios.post("/posts", {
      const res = await http.post(`market`, postItems);
      window.location.replace("/market");
    } catch (err) {
      setError(true);
    }
  };

  return (
    <>
      <Nav />
      <div class="templatemo-content col-1 light-gray-bg">
        <div class="templatemo-content-widget templatemo-login-widget white-bg">
          <header class="text-center">
            <div class="square"></div>
            <h1>Rent and Flex</h1>
            <p>
              You are a MarketPlace. We make it easier for your online customers
              to buy from your shop.
            </p>
          </header>
          {error && <span style={{ color: "red" }}> Post is unsuccessful</span>}

          <form
            id="contact"
            onSubmit={writeHandler}
            encType="multipart/form-data"
            method="POST"
            input
            value="csrf_token()"
            className="templatemo-login-form"
          >
            <div class="form-group">
              <input
                class="form-control"
                type="name"
                name="title"
                placeholder="Enter Name of Expenses"
                autocomplete="on"
                required="true"
                onChange={(e) => titleRef(e.target.value)}
              />
            </div>
            <div class="form-group">
              <input
                type="number"
                class="form-control"
                name="amount"
                id="amount"
                placeholder="Enter Amount"
                autocomplete="on"
                required
                onChange={(e) => landmarkRef(e.target.value)}
              />
            </div>

            <div class="form-group">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="Select Date of expenses"
                dateFormat="dd/MM/yyyy"
                isClearable
              />
            </div>
            <div class="form-group">
              <input
                class="form-control"
                type="text"
                name="approval"
                placeholder="Approved by"
                autocomplete="on"
                required
                onChange={(e) => phoneRef(e.target.value)}
              />
            </div>

            <div class="form-group">
              <button type="submit" class="templatemo-blue-button width-100">
                Post
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddExpense;
