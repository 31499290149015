import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/authContext";

function Nav() {
  const { currentUser } = useContext(AuthContext);
  let admin = currentUser.role === 2;

  return (
    <div className="templatemo-top-nav-container">
      <div className="row">
        <nav className="templatemo-top-nav col-lg-12 col-md-12">
          <ul className="text-uppercase">
            <li>
              <a href="/" className="active">
Dashboard              </a>
            </li>
            <li>
                <Link to="/admin">Admin Panel</Link>
              </li>
            <li>
              <Link to="/addVenue">Add Venues</Link>
            </li>
            
             { admin && (
                <li>
                  <Link to="/register">Sign up form</Link>
                </li>
              )
            }
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Nav;
