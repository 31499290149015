import { React, useState, useContext } from "react";
import Footer from "../components/footer";

import { useNavigate, useLocation } from "react-router-dom";
import http, { marketUrlEndpoint } from "../components/http";
import { AuthContext } from "../context/authContext";
import useSWR, { mutate } from "swr";
import toast from "react-hot-toast";

const fetcher = (...args) => fetch(...args).then((response) => response.json());

function RegisterAdmin(props) {
  const [isOpen, setIsOpen] = useState(false);
const toggleOpen = () => {
  setIsOpen((open) => !open);
};
  let endPoint = marketUrlEndpoint;
  const post = useLocation().state;
  const { currentUser } = useContext(AuthContext);
  const { isLoading, data: market, error } = useSWR(endPoint, fetcher);

  const navigate = useNavigate();

  const [username, postUser] = useState(post?.username || "");
  const [password, passUser] = useState("");
  const [email, emailUser] = useState(post?.email || "");
  const [firm, firmUser] = useState(post?.firm || "");
  const [rating, setRating] = useState(post?.rating || "");

  const [errors, setError] = useState(false);
  //Select box
  let [role, setRole] = useState(post?.role || "");

  if (isLoading) <p>{"data is loading"}</p>;
  // const handleSelect = () => {
  //  setvalue(value);
  //};
  // if (currentUser.role == 3) {
  //  setUser = true;
  // }
  const options = [
    { label: "Staff", value: "001" },
    { label: "Admin", value: "002" },
    { label: "SuperAdmin", value: "003" },
  ];
  // console.log(role, currentUser.firm);
  //Status of user
  const options2 = [
    { label: "IsActive", value: "Active" },
    { label: "IsPending", value: "Pending" },
    { label: "IsDeactivated", value: "Deactivated" },
  ];
  // Registration
  const registerHandler = async (e) => {
    e.preventDefault();
    setError(false);
    const postI={
      username,
      password,
      email,
      role,
      firm,
      rating,
    }
    console.log(postI)
    try {
      post
        ? await http.put(`/users/${post.id}`, {
            ...post,
            username,
            password,
            email,
            role,
            firm,
            rating,
          })
        : await http.post(`auth/register`, {username, password, email, role, firm,rating,
          });
      toast.success("Post was successful");

      navigate("/");
    } catch (err) {
      toast.error(err.response.data);
      setError(true);
    }
  };

  return (
    <div className="templatemo-content col-1 light-gray-bg">
      <div className="templatemo-content-widget templatemo-login-widget white-bg">
        <header className="text-center">
          <div className="square"></div>
          <h1>Events Plc</h1>
          <p>Excellent Event Hall Managers</p>
        </header>
        {errors && (
          <span style={{ color: "red" }}> {error} Registration failed</span>
        )}
        <form
          method="post"
          onSubmit={registerHandler}
          className="templatemo-login-form"
        >
          <div className="form-group">
            <div className="">
              <div>
                <span>Select Market Name</span>
              </div>

              <select
                onChange={(e) => firmUser(e.target.value)}
                className="form-control"
                value={firm}
              >
                {market
                  ? market.map((option) => (
                      <>
                        {" "}
                        <option value="" defaultValue="selected" hidden="hidden">
                          Choose here
                        </option>
                        <option key={option.id} value={option.title}>
                          {option.title}
                        </option>
                      </>
                    ))
                  : isLoading && <p>Market Names are Loading...</p>}
              </select>
            </div>
          </div>
          <div className="form-group">
            <div className="input-group">
              <div className="input-group-addon">
                <i className="fa fa-user fa-fw"></i>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Username"
                required="true"
                value={username}
                onChange={(e) => postUser(e.target.value)}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="input-group">
              <div className="input-group-addon">
                <i className="fa fa-user fa-fw"></i>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Email"
                required="true"
                value={email}
                onChange={(e) => emailUser(e.target.value)}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="input-group">
              
              <input
                  type={isOpen?"text":"password"}
                  value={password}
                className="form-control"
                placeholder="Enter Password"
                required="true"
                onChange={(e) => passUser(e.target.value)}
              />
              <div className="input-group-addon">
              <i className={isOpen?"fa fa-key fa-fw":"fa fa-eye"}onClick={toggleOpen}></i>
              </div>
            </div>

          </div>

          <div className="form-group">
            <div className="">
              <div>
                <span>Select Role Type</span>
              </div>

              <select
                onChange={(e) => setRole(e.target.value)}
                className="form-control"
                value={role}
              >
                {options.map((option) => (<>
                  <option value="" defaultValue="selected" hidden="hidden">
                  Choose here
                </option>
                  <option key={option.value} value={option.value}>{option.label}</option>
                  </>))}
              </select>
            </div>
          </div>
          <div className="form-group">
            <div className="">
              <div>
                <span>Select Status</span>
              </div>

              <select
                onChange={(e) => setRating(e.target.value)}
                className="form-control"
                value={rating}
              >
                
                {options2.map((option) => ( 
                  <><option value="" defaultValue="selected" hidden="hidden">
                  Choose here
                </option>
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                  </>))}
              </select>
            </div>
          </div>
          <div className="form-group">
            <button type="submit" className="templatemo-blue-button width-100">
              Register
            </button>
          </div>
        </form>
      </div>
    
      <Footer />
    </div>
  );
}

export default RegisterAdmin;
