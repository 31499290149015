import React, { useState, useContext } from "react";
import Nav from "../components/nav";
import http, { venueUrlEndpoint } from "../components/http";
//import DatePicker from "react-datepicker";
import Datetime from "react-datetime";

import { useLocation } from "react-router-dom";
//import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { AuthContext } from "../context/authContext";
import useSWR, { mutate } from "swr";

const fetcher = (...args) => fetch(...args).then((response) => response.json());

function AddBooking() {
  const { currentUser } = useContext(AuthContext);
  const post = useLocation().state;
  //Fetch params

  let endPoint = venueUrlEndpoint + currentUser.firm;

  const { isLoading, data: venues, error } = useSWR(endPoint, fetcher);

  const [payment, rentRef] = useState(post?.payment || "");

  const [errors, setErrors] = useState(false);
  const [venue, setVenue] = useState(post?.venue || "");

  const [customer, setName] = useState(post?.customer || "");
  const [phone, setPhone] = useState(post?.phone || "");

  const [email, setEmail] = useState(post?.email || "");
  const [address, setAddress] = useState(post?.address || "");
  const [title, setTitle] = useState(post?.purpose || "");

  // Date state
  const [start, setStartDate] = useState(post?.start || new Date());
  const [end, setEndDate] = useState(post?.start || new Date());

  //EndDate

  let today = moment().format("DD MMMM YYYY hh:mm:ss a");
  console.log(today);
  let recordDate = post?.recordDate || today;

  //Paid date state
  const [pDate, setPaidDate] = useState(post?.paidDate || new Date());
  let paDate = moment(pDate).format("DD MMMM YYYY hh:mm:ss a");
  console.log(paDate);
  const paidDate = post?.paidDate || paDate;

  //submit handler
  const writeHandler = async (e) => {
    e.preventDefault();
    setErrors(false);
    const data = {
      venue,
      customer,
      title,
      start,
      end,
      phone,
      paidDate,
      recordDate,
      firm: currentUser.firm,
      username: currentUser.username,
    };
    //add payment
    if (payment) {
      const postItems = {
        venue,
        customer,
        payment,
        phone,
        email,
        address,
        paidDate,
        recordDate,
        firm: currentUser.firm,
        username: currentUser.username,
      };
      console.log(postItems);
      try {
        const res = await http.post(`payment`, postItems);
        console.log(res.data);
      } catch (err) {
        setErrors(true);
      }
    }

    try {
      // const res = await axios.post("/posts", {
      const res = await http.post(`events`, data);
      window.location.replace("/bookings");
      console.log(res.data);
    } catch (err) {
      setErrors(true);
    }
  };

  return (
    <>
      <Nav />
      <div class="templatemo-content col-1 light-gray-bg">
        <div class="templatemo-content-widget templatemo-login-widget white-bg">
          <header class="text-center">
            <div class="square"></div>
            <h1>Events Plc</h1>
            <p>Excellent EventPlace Management</p>
          </header>
          {errors && (
            <span style={{ color: "red" }}> Post is unsuccessful</span>
          )}

          <form
            id="contact"
            onSubmit={writeHandler}
            encType="multipart/form-data"
            method="POST"
            input
            value="csrf_token()"
            className="templatemo-login-form"
          >
            {error && <p>{error.message}</p>}
            <div class="form-group">
              <div className="">
                <div>
                  <span>Select Venue Name</span>
                </div>

                <select
                  onChange={(e) => setVenue(e.target.value)}
                  class="form-control"
                >
                  {venues
                    ? venues.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.title}
                        </option>
                      ))
                    : isLoading && <p>Tenants Name are Loading...</p>}
                </select>
              </div>
            </div>
            {/* Client Information*/}
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-addon"></div>
                <input
                  name="Name"
                  type="text"
                  class="form-control"
                  placeholder="Enter Name of Customer"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-addon"></div>
                <input
                  name="Phone of subscriber"
                  type="text"
                  class="form-control"
                  placeholder="Enter Phone No"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-addon"></div>
                <input
                  name="Email of subscriber"
                  type="text"
                  class="form-control"
                  placeholder="Enter Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-addon"></div>
                <input
                  name="Address"
                  type="text"
                  class="form-control"
                  placeholder="Enter Address"
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-addon"></div>
                <input
                  name="title"
                  type="text"
                  class="form-control"
                  placeholder="Enter Name of Event"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
            </div>
            {/* */}
            <div class="form-group">
              <input
                type="number"
                class="form-control"
                name="rent"
                id="rent"
                placeholder="Enter Payment"
                required
                onChange={(e) => rentRef(e.target.value)}
              />
            </div>
            {/* Date Time */}
            <div class="form-group">
              <div class="checkbox squaredTwo">
                <label>
                  <span>Start Date</span>
                </label>
                <Datetime
                  value={start}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="DD/MM/YYYY"
                />
              </div>
            </div>

            <div class="form-group">
              <div class="checkbox squaredTwo">
                <label>
                  <span>End Date Date</span>
                </label>
                <Datetime
                  dateFormat="DD/MM/YYYY"
                  value={end}
                  onChange={(date) => setEndDate(date)}
                />
              </div>
            </div>
            <div class="form-group">
              <div class="checkbox squaredTwo">
                <label>
                  <span>Date of Payment</span>
                </label>
                <Datetime
                  value={paidDate}
                  onChange={(date) => setPaidDate(date)}
                />
              </div>
            </div>

            <div class="form-group">
              <button type="submit" class="templatemo-blue-button width-100">
                Post
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddBooking;
