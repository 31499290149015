import React, { useContext } from "react";
import useSWR, { mutate } from "swr";
import { Link } from "react-router-dom";

import { AuthContext } from "../context/authContext.js";
import http, { paymentUrlEndpoint } from "../components/http.jsx";
import toast from "react-hot-toast";

const handleDelete = async ({ id }) => {
  try {
    await http.delete(paymentUrlEndpoint + id);
    mutate();
    toast.success("Post Added Successfully");
  } catch (error) {
    toast.error(error.message, {
      duration: 60,
    });
  }
};

const fetcher = (...args) => fetch(...args).then((response) => response.json());

function PaymentList(props) {
  const { currentUser } = useContext(AuthContext);
  let endPoint = paymentUrlEndpoint + currentUser.firm;
  const { isLoading, data: payment, error } = useSWR(endPoint, fetcher);
  let num = 1;
  /* const changeHandler = (e) => {
    payment.filter((f) => f.rent.toLowerCase().includes(e.target.value));
  };*/
  //const filter = payment.filter((f) => f.propertyId.includes(props.id));
  //console.log(filter);
  const currentDate = new Date().toLocaleDateString();

  const defaultHandler = (currentdate) => {
    return payment.filter((f) => f.endDate >= currentdate);
  };

  const expiredRents = defaultHandler(currentDate);

  if (error) return <p>{error.message}</p>;

  return (
    <div className="templatemo-content-container">
      <div className="templatemo-flex-row flex-content-row">
        <div className="templatemo-flex-row flex-content-row">
          <div className="templatemo-content-widget white-bg col-2">
            <i className="fa fa-times"></i>
            <div className="media margin-bottom-30">
              <div className="media-left padding-right-25">
                <a href="#">
                  <img
                    className="media-object img-circle templatemo-img-bordered"
                    src="assets/images/person.jpg"
                    alt="Sunset"
                  />
                </a>
              </div>
              <div className="media-body">
                <h2 className="media-heading text-uppercase blue-text">
                  {currentUser.username}
                </h2>
                <p>{currentUser.email}</p>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table">
                <tbody>
                  <tr>
                    <td>
                      <div className="circle green-bg"></div>
                    </td>
                    <td>Total Payments</td>
                    <td>{payment && payment.length}</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="circle green-bg"></div>
                    </td>
                    <td>
                      <Link to={"/defaults"}>Expired Rents</Link>
                    </td>
                    <td>{expiredRents && expiredRents.length}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* The table */}

      {/* The search 
      <form className="templatemo-search-form" role="search">
        <button type="submit" className="fa fa-search"></button>
        <input
          type="text"
          className="form-control"
          placeholder="Search Market here"
          name="srch-term"
          id="srch-term"
          onChange={changeHandler}
        />
      </form>*/}
      <div className="templatemo-content-widget no-padding">
        <div className="panel panel-default table-responsive">
          <table className="table table-striped table-bordered templatemo-user-table">
            <thead>
              <tr>
                <td>
                  <a href="" className="white-text templatemo-sort-by">
                    # <span className="caret"></span>
                  </a>
                </td>
                <td>
                  <a href="" className="white-text templatemo-sort-by">
                    Paid On <span className="caret"></span>
                  </a>
                </td>

                <td>
                  <a href="" className="white-text templatemo-sort-by">
                    Customer <span className="caret"></span>
                  </a>
                </td>

                <td>
                  <a href="" className="white-text templatemo-sort-by">
                    Phone <span className="caret"></span>
                  </a>
                </td>
                <td>Payment</td>
                <td>Edit</td>

                <td>Delete</td>
              </tr>
            </thead>
            {payment
              ? payment.map((payment, key) => {
                  console.log(payment);
                  return (
                    <tbody>
                      <tr key={key}>
                        <td>{num++}</td>
                        <td>{payment.paidDate}</td>

                        <td>{payment.customer}</td>
                        <td>{payment.phone}</td>
                        <td>{payment.payment}</td>
                        <td>
                          <Link
                            to={`/addBooking/`}
                            state={payment}
                            className="templatemo-edit-btn"
                          >
                            {" "}
                            Edit
                          </Link>
                        </td>

                        <td>
                          <Link
                            to=""
                            onClick={() => handleDelete(payment)}
                            className="templatemo-edit-btn"
                          >
                            Delete
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              : isLoading && <p>Loading...</p>}
          </table>
        </div>
      </div>
      {/* Pagination */}
      <div className="templatemo-content-widget white-bg col-1 templatemo-position-relative templatemo-content-img-bg">
        <img
          src="assets/images/sunset-big.jpg"
          alt="Sunset"
          className="img-responsive content-bg-img"
        />
        <i className="fa fa-heart"></i>
        <h2 className="templatemo-position-relative white-text">Sunset</h2>
        <div className="view-img-btn-wrap">
          <a href="" className="btn btn-default templatemo-view-img-btn">
            View
          </a>
        </div>
      </div>
    </div>
  );
}

export default PaymentList;
